.wrapper {
  margin: 0 auto;
  display: flex;
  padding: 20px 40px;
}
.h1 {
  font: normal normal normal 40px/1.1em georgia, palatino, "book antiqua", "palatino linotype", serif;
  color: #887f63;
  margin-bottom: 16px;
  padding: 0 40px;
}
.manualText {
  width: 70%;
  margin-left: 2vw;
  ul {
    padding-left: 20px;
    margin-bottom: 12px;

    li {
      font: normal normal normal 16px/1.3em helvetica-w01-light, helvetica-w02-light, sans-serif;
      color: #272725;

      margin-bottom: 8px;
    }
}

.h2 {
  font: normal normal normal 30px/1.2em georgia, palatino, "book antiqua", "palatino linotype", serif;
  color: #272725;

  margin-bottom: 12px;
}
.h3 {
  font: normal normal normal 22px/1.3em georgia, palatino, "book antiqua", "palatino linotype", serif;
  color: #554a4d;

  margin-bottom: 12px;
}
.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.commandsBlock {
  display: flex;
  :first-child {
    margin-right: 20px;
    width: 300px;
  }
}
.langSelect {
  width: 240px;
  margin-bottom: 20px;
}
.p {
  font: normal normal normal 16px/1.3em helvetica-w01-light, helvetica-w02-light, sans-serif;
  color: #272725;
  line-height: 1.75em;
  margin-bottom: 12px;
  > b {
    font-family: Georgia, serif;
  }
}
a {
  color: #272725;
}
section img {
  display: block;
  margin: 15px auto;
  max-height: 570px;
  max-width: 265px;
  border: 1px solid #7c6d73;
  }
}
.wideImg {
  max-width: 50%;
  margin: 15px auto;
}

.p + .h1 {
  margin-top: 32px;
}

.p + .h2 {
  margin-top: 32px;
}

.p + .h3 {
  margin-top: 32px;
}

.ul + .h1 {
  margin-top: 32px;
}

.ul + .h2 {
  margin-top: 32px;
}

.ul + .h3 {
  margin-top: 32px;
}

.h1 + .h2 {
  margin-top: 24px;
}

.h2 + .h3 {
  margin-top: 24px;
}
@media screen and (max-width: 768px) {
  .manualText {
    section img {
      margin: 15px auto;
    }
  }
    .wrapper {
      padding: 0 7vw 2vh;
    }
    .h1 {
      font-size: 34px;
      text-align: center;
      padding: 0;
    }
    .h2 {
      font-size: 24px;
    }
    .h3 {
      font-size: 18px;
    }
    .p {
      font-size: 14px;
    }
    .ul {
      .li {
        font-size: 14px;
      }
    }
    .p + .h1 {
      margin-top: 24px;
    }

    .p + .h2 {
      margin-top: 24px;
    }

    .p + .h3 {
      margin-top: 24px;
    }

    .ul + .h1 {
      margin-top: 24px;
    }

    .ul + .h2 {
      margin-top: 24px;
    }

    .ul + .h3 {
      margin-top: 24px;
    }

    .h1 + .h2 {
      margin-top: 18px;
    }

    .h2 + .h3 {
      margin-top: 18px;
    }
    .flex {
      flex-direction: column;
    }
    .wideImg {
      max-width: 85%;
    }
    .langSelect {
      width: 100% !important;
    }
    .commandsBlock {
      flex-direction: column;
    }
}
@media screen and (max-width: 992px) {
  .manualText {
    margin: 0;
    width: 100%;
  }
}