@import '../../../../styles/variables.scss';

.root {
  background: #ffffff;
  box-shadow: 0px 8px 24px -8px rgba(0, 17, 26, 0.5), 0px 20px 80px -20px rgba(0, 17, 26, 0.5);
  border-radius: 8px;
  padding: 64px 40px 40px 40px;
  max-width: 800px;

  @media (max-width: $lg) {
    padding: 24px 16px 16px;
  }
}

.body {
  display: flex;
  margin-bottom: 40px;

  @media (max-width: $lg) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
  }
}

.metadata {
  font-family: Roboto;
  font-style: normal;

  @media (max-width: $lg) {
    text-align: center;
  }
}

.message {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: $onSurfaceMediumEmphasis;
  margin-bottom: 16px;

  span {
    font-weight: 700;
  }

  @media (max-width: $lg) {
    font-size: 16px;
    line-height: 20px;
  }
}

.fileName {
  word-break: break-all;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
  margin-bottom: 16px;

  @media (max-width: $lg) {
    font-size: 20px;
    line-height: 24px;
  }
}

.size {
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  color: $onSurfaceMediumEmphasis;

  @media (max-width: $lg) {
    font-size: 16px;
    line-height: 20px;
  }
}

.fileType {
  margin-right: 48px;

  @media (max-width: $lg) {
    margin-right: 0;
    margin-bottom: 24px;
  }
}
