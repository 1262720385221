.body {
    display: flex;
    height: 100%;
    flex-flow: column;
    p {
        font: normal normal normal 15px/1.3em helvetica-w01-light, helvetica-w02-light, sans-serif;
        color: #272725;
    }
    p:first-of-type {
        margin-bottom: 20px;
    }
    p:last-of-type {
        flex-grow: 1;
    }
}
.divider {
    border-top: 1px solid rgba(136, 127, 99, 1);
    border-bottom: 1px solid rgba(136, 127, 99, 1);
    padding: 2px 0 0 0;
    width: 20%;
    margin: 15px auto;
}
.header {
    text-align: center;
    color: #554a4d;
    font: normal normal 24px/1.2em georgia, palatino, "book antiqua", "palatino linotype", serif;
}
.readMore {
    color: #9c8e93;
    margin-top: 20px;
    text-decoration: none;
}
@media screen and (max-width: 768px) {
    .readMore {
        text-align: center;
    }
    .header {
        font-size: 20px;
    }
    .body p {
        font-size: 14px;
    }
}
