@import "../../../styles/variables.scss";

.root {
  font: italic normal normal 18px/1.3em droid-serif-w01-regular, droid-serif-w02-regular, droid-serif-w10-regular, serif;
  padding: 4px 50px;
  cursor: pointer;
  background-color: rgba(85, 74, 77, 1);
  color: #f1f1e4;
  transition: background-color 0.2s;
  border: none;
  width: 100%;

  &:hover {
    background: rgba(124, 109, 115, 1);
    transition: background-color ease-in 0.2s;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
@media screen and (max-width: 768px) {
  .root {
    padding: 12px 50px;
  }
}