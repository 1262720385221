@import '../../../../styles/variables.scss';

.root {
  padding: 0 20px;
}

.image {
  width: 120px;
  height: 160px;

  @media (max-width: $lg) {
    width: 60px;
    height: 80px;
  }
}
