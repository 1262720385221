.main {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-bottom: 40px;
}
.mainHeader {
  margin: 40px 0;
}
.screens {
  display: flex;
  justify-content: center;
  gap: 2.5vw;
  img {
    max-height: 470px;
    max-width: 20vw;
  }
}
.screens :not(:last-of-type) {
  margin-right: 2.5vw;
}
.userGuide {
  text-decoration: none;
  margin-top: 20px;
  font: normal normal 21px/1.2em georgia, palatino, "book antiqua", "palatino linotype", serif;
}
.download {
  &Section {
    background-color: rgb(192,186,188);

    &Content {
      max-width: 650px;
      align-items: center;
      padding: 45px 0;
      margin: 0 auto;
      display: flex;
    }
  }
  &Link {
    margin-left: 70px;
    img {
      width: 190px;
      height: 56px;
    }
  }
  &Text {
    width: 285px;
    height: 65px;
    line-height: 1.5em;
  }
}
@media screen and (max-width: 768px) {
  .mainHeader {
    margin: 20px 0;
  }
  .screens {
    flex-flow: column;
    img {
      margin: 0 !important;
      max-width: none;
      max-height: none;
    }
  }
  .download {
    &SectionContent{
      gap: 20px;
      flex-flow: column;
      margin: 0;
      text-align: center;
      padding: 15px 10vw;
      max-width: none;
    }
    &Text {
      width: auto;
      height: auto;
    }
    &Link {
      margin: 0;
    }
  }
}