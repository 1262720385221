.manualMenu {
  position: sticky;
  display: block;
  top: 50px;
  font: normal normal 16px/1.2em georgia, palatino, "book antiqua", "palatino linotype", serif;
  width: 250px;
  list-style-type: none;
  &Item a {
    color: #7c6d73;
    text-decoration: none;
  }
  &Item {
    padding: 10px;
  }
}
.manualNavigation {
  display: block;
}
.active {
  background-color: #c7c7bd;
}
@media screen and (max-width: 992px) {
  .manualMenu {
    display: none;
  }
}