.decoration {
    width: 90px;
    height: 23px;
    margin: 0 10px;
}
.text {
    font: normal normal 21px/1.2em georgia, palatino, "book antiqua", "palatino linotype", serif;
    color: #554a4d;
}
.decoratedText {
    display: flex;
    align-items: center;
}
@media screen and (max-width: 768px) {
    .text {
        font-size: 18px;
    }
    .decoratedText {
        flex-flow: column;
        gap: 10px;
    }
}
