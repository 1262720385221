.mainImage {
    position: relative;
    height: 379px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.subtitle {
    height: auto;
    padding: 15px;
    text-align: center;
    color: #f1f1e4;
    background-color: rgba(124, 109, 115, 1);
    align-items: center;
    display: flex;
    justify-content: center;
    font: italic normal normal 30px/1.2em georgia, palatino, "book antiqua", "palatino linotype", serif;
}

.content {
    background-color: #f1f1e4;
}
.pageTitle {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 10px;
    font: italic normal normal 40px/1.1em georgia, palatino, "book antiqua", "palatino linotype", serif;
    color: #f1f1e4;
}
.poweredBy {
    font: normal normal normal 24px/1.1em georgia, palatino, "book antiqua", "palatino linotype", serif;
    color: #f1f1e4;
    position: absolute;
    right: 1%;
    bottom: 1%;
}

@media screen and (max-width: 768px) {
    .poweredBy {
        display: none;
    }
    .mainImage {
        height: 135px;
    }
    .pageTitle {
        font-size: 1.6rem;
        text-align: center;
    }
    .subtitle{
        z-index: 1;
        font-size: 1.3rem;
        display: block;
    }
}