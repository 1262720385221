@import "../../../../styles/variables.scss";

.wrapper {
  max-width: 931px;
  margin: 0 auto;
  padding: 0 40px 20px 40px;
}

.h1 {
  font: italic normal normal 40px/1.1em georgia, palatino, "book antiqua", "palatino linotype", serif;
  color: #887f63;
  margin-bottom: 16px;
}
.h1:first-of-type {
  padding-top: 16px;
  text-align: center;
}

.h2 {
  font: italic normal normal 30px/1.2em georgia, palatino, "book antiqua", "palatino linotype", serif;
  color: #272725;

  margin-bottom: 12px;
}

.h3 {
  font: normal normal normal 21px/1.3em georgia, palatino, "book antiqua", "palatino linotype", serif;
  color: #554a4d;

  margin-bottom: 12px;
}

.p {
  font: normal normal normal 15px/1.3em helvetica-w01-light, helvetica-w02-light, sans-serif;
  color: #272725;

  margin-bottom: 12px;
}
a {
  color: #272725;
}
.ul {
  padding-left: 20px;
  margin-bottom: 12px;

  .li {
    font: normal normal normal 15px/1.3em helvetica-w01-light, helvetica-w02-light, sans-serif;
    color: #272725;

    margin-bottom: 8px;
  }
}

.p + .h1 {
  margin-top: 32px;
}

.p + .h2 {
  margin-top: 32px;
}

.p + .h3 {
  margin-top: 32px;
}

.ul + .h1 {
  margin-top: 32px;
}

.ul + .h2 {
  margin-top: 32px;
}

.ul + .h3 {
  margin-top: 32px;
}

.h1 + .h2 {
  margin-top: 24px;
}

.h2 + .h3 {
  margin-top: 24px;
}
@media screen and (max-width: 768px) {
    .wrapper {
      padding: 0 7vw 2vh;
    }
    .h1 {
      font-size: 34px;
    }
    .h2 {
      font-size: 24px;
    }
    .h3 {
      font-size: 18px;
    }
    .p {
      font-size: 14px;
    }
    .ul {
      .li {
        font-size: 14px;
      }
    }
    .p + .h1 {
      margin-top: 24px;
    }
    
    .p + .h2 {
      margin-top: 24px;
    }
    
    .p + .h3 {
      margin-top: 24px;
    }
    
    .ul + .h1 {
      margin-top: 24px;
    }
    
    .ul + .h2 {
      margin-top: 24px;
    }
    
    .ul + .h3 {
      margin-top: 24px;
    }
    
    .h1 + .h2 {
      margin-top: 18px;
    }
    
    .h2 + .h3 {
      margin-top: 18px;
    }
}