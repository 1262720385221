@import "../../../styles/variables.scss";

.root {
  border: none;
  padding: 12px;
  outline: none;
  background-color: rgba(176, 176, 163, 1);
  width: 100%;

  font: italic normal normal 18px/1.3em droid-serif-w01-regular, droid-serif-w02-regular, droid-serif-w10-regular, serif;
}
::placeholder {
  color: #f1f1e4;
}
.root:focus {
  background-color: rgba(124, 109, 115, 1);
  color: #f1f1e4;
}