@import '../../styles/variables.scss';

.root {
  margin-bottom: 48px;

  @media (max-width: $lg) {
    margin-bottom: 16px;
  }
}

.center {
  margin: 0 auto 48px;

  @media (max-width: $lg) {
    margin-bottom: 16px;
  }
}

.link {
  .logo {
    width: 100%;
    height: 100%;
    max-width: 304px;

    @media (max-width: $lg) {
      max-width: 152px;
    }
  }
}
