.description {
    display: flex;
    padding: 5vh 2vw;
    justify-content: center;
}
.descriptionBlock {
    max-width: 280px;
    margin: 0 30px;
}
.hr {
    border: 1px dashed rgba(124, 109, 115, 0.5);
    height: 170px;
    margin-top: 75px;
}
.poweredBy {
    position: absolute;
    font-size: 28px;
}
@media screen and (max-width: 768px) {
    .descriptionBlock {
        max-width: none;
        margin: 30px 0;
    }
    .description {
        gap: 0;
        flex-flow: column-reverse;
        padding: 0 10vw;
        justify-content: initial;
    }
    .hr {
        border-top: 1px dashed rgba(124, 109, 115, 1);
        height: auto;
        margin: 0;
    }
}