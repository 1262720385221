@import '../../../styles/variables.scss';

.root {
  background-color: $primaryMain;
  padding: 18px 70px;
  border: 2px solid $primaryMain;
  border-radius: 8px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #fff;
  text-decoration: none;

  &:hover {
    background: #1421a3;
    transition: background-color ease-in 0.2s;
  }

  @media (max-width: $lg) {
    padding: 12px 76px;
  }
}

.fullWidth {
  display: inline-block;
  width: 100%;
  text-align: center;
}
