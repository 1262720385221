@import '../../styles/variables.scss';

.root {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  display: flex;
  padding-top: 52px;

  @media (max-width: $lg) {
    padding-top: 42px;
  }
}

.copyright {
  flex-grow: 1;
}

.link {
  text-decoration-line: underline;
  color: #fff;
}

.first {
  margin-right: 32px;
}
