.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
}

.title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #fff;
  text-transform: uppercase;
}

.link {
  color: #fff;

  &:hover {
    color: #ccc;
    transition: color ease-out 0.3s;
  }
}
