@import '../../styles/variables.scss';

.root {
  display: flex;
  justify-content: center;
  padding: 70px 0;
  margin: 0 8px;

  @media (max-width: $lg) {
    padding: 0;
  }
}

.content {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: $lg) {
    min-height: 100vh;
    padding: 16px 0;
  }
}

.main {
  max-width: 552px;
  display: flex;

  @media (max-width: $lg) {
    display: block;
    max-width: 100%;
  }
}

.card {
  margin-bottom: 40px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 210px;
  right: 0;

  @media (max-width: $lg) {
    position: static;
    margin-bottom: 24px;
  }
}

.download {
  align-self: flex-end;
}
