$sm: 360px;
$md: 768px;
$lg: 1080px;
$xl: 2560px;

$background: #252d3d;

//On Surface/Medium Emphasis
$onSurfaceMediumEmphasis: rgba(0, 0, 0, 0.6);
/* primary/main */
$primaryMain: #1a237e;
/* On Surface/High Emphasis */
$onSurfaceHighEmphasis: rgba(0, 0, 0, 0.87);
/* Outline/Outline */
$outlineOutline: rgba(0, 0, 0, 0.12);
