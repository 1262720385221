.main {
    max-width: 435px;
    margin: 0 auto;
    padding-bottom: 25px;
    text-align: center;
}
.mainHeader {
    display: flex;
    justify-content: center;
    padding: 40px 0;
}
.cases {
    background-color: #c0babc;
    padding: 60px 40px 40px 40px;
    display: flex;
    flex-flow: column;
    align-items: center;
}
.divider {
    border-top: 1px solid rgba(136, 127, 99, 1);
    border-bottom: 1px solid rgba(136, 127, 99, 1);
    padding: 2px 0 0 0;
    width: 40%;
    margin: 60px auto 20px;
}
.caseBlock {
    text-align: center;
    p:not(:first-of-type) {
        color: #554a4d;
        margin: 20px 0;
    }
    h2 {
        color: #554a4d;
        font: normal normal 21px/1.2em georgia, palatino, "book antiqua", "palatino linotype", serif;
    }
}
.flex {
    display: flex;
    justify-content: center;
}
.hr {
    border: 1px dashed rgba(124, 109, 115, 0.5);
    height: 220px;
    margin: 0 80px;
}
.contactBtn {
    border: none;
    color: #f1f1e4;
    font: italic normal 18px/1.2em georgia, palatino, "book antiqua", "palatino linotype", serif;
    background-color: rgba(85, 74, 77, 1);
    cursor: pointer;
    margin-top: 20px;
    width: 310px;
    height: 40px;
}
@media screen and (max-width: 768px) {
    .main {
        padding: 3vh 10vw;
    }
    .cases {
        padding: 30px 0;
    }
    .mainHeader {
        padding: 0 0 3vh 0;
    }
    .flex {
        flex-flow: column;
    }
    .caseBlock {
        p:not(:first-of-type) {
            margin: 3vh 0;
        }
    }
    .hr {
        border-top: 1px dashed rgba(124, 109, 115, 1);
        height: auto;
        margin: 0;
        width: 70vw;
        margin-bottom: 3vh;
    }
    .divider {
        width: 10vw;
        margin: 20px auto;
    }
}