.inputSection:not(:last-child) {
  padding-bottom: 4px;
}
.fields {
  display: flex;
}
.form {
  width: 100%;
}
.inputs {
  margin-right: 4px;
}
.inputs,
.textareaSection {
  flex-grow: 1;
}
.submitBtn {
  margin-top: 6px;
  float: right;
}
.thanks {
  margin-top: 4px;
  font: normal normal normal 15px/1.3em helvetica-w01-light, helvetica-w02-light, sans-serif;
  color: #272725;
}
@media screen and (max-width: 768px) {
  .inputSection {
    padding-bottom: 4px;
  }
  .inputs {
    margin: 0;
  }
  .fields {
    flex-flow: column;
  }
  .submitBtn {
    width: 100%;
    float: none;
  }
}