.footer {
    background-color: rgba(39, 39, 37, 1);
    height: 72px;
    padding-top: 10px;
}
.footerText {
    font: normal normal 12px/1.2em georgia, palatino, "book antiqua", "palatino linotype", serif;
    color: #aa9f7b;
    max-width: 650px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 768px) {
    .footerText {
        flex-flow: column-reverse;
        padding: 0 10vw;
        font-size: 13px;
        gap: 8px;
        margin: 0;
    }

}