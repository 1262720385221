.about {
    max-width: 560px;
    margin: 0 auto;
    padding-bottom: 50px;
}
.sectionHeader {
    display: flex;
    justify-content: center;
    padding: 40px 0 20px 0;
}

.testimonials {
    background-color: #c0babc;
    padding: 20px 20px 60px 20px;
    display: flex;
    justify-content: center;
    flex-flow: column;
    align-items: center;
}
p {
    font: normal normal normal 15px/1.3em helvetica-w01-light, helvetica-w02-light, sans-serif;
    color: #554a4d;
}
.testimonial {
    text-align: center;
    max-width: 280px;
    display: flex;
    flex-flow: column;
    margin: 30px 10px;
    &Name {
        margin-top: auto;
        font: normal normal 16px/1.2em georgia, palatino, "book antiqua", "palatino linotype", serif;
    }
}
.divider {
    border-top: 1px solid rgba(136, 127, 99, 1);
    border-bottom: 1px solid rgba(136, 127, 99, 1);
    padding: 2px 0 0 0;
    width: 10%;
    margin: 15px auto;
}
.hr {
    border: 0;
    border-right: 1px dashed rgba(124, 109, 115, 1);
    height: 80px;
    margin-top: 35px;
}
.flex {
    display: flex;
}

@media screen and (max-width: 768px) {
    .about {
        padding: 3vh 10vw;
    }
    .hr {
        display: none;
    }
    .flex {
        flex-flow: column;
    }
    .testimonial {
        margin: 10px 0;
    }
    .testimonial:not(:first-of-type) {
        margin: 2vh 0;
    }
    .testimonials {
        padding: 2vh 0 2vh 0;
    }
    .sectionHeader {
        text-align: center;
        padding: 0 0 2vh 0;
    }
    p {
        font-size: 14px;
    }
}