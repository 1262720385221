@import '../../../../styles/variables.scss';

.root {
  margin-right: 48px;
  position: relative;

  @media (max-width: $lg) {
    display: none;
  }

  &:after {
    content: '';
    position: absolute;
    background-image: url('../../../../assets/images/screen.png');
    width: 90%;
    height: 90%;
    background-size: contain;
    background-repeat: no-repeat;
    left: 20px;
    top: 80px;
  }
}

.img {
  width: 400px;
  height: 800px;
}
