.main {
    width: 650px;
    margin: 0 auto;
    padding-bottom: 50px;
    text-align: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}
.mainHeader {
    display: flex;
    justify-content: center;
    padding-top: 40px;
    margin-bottom: 20px;
}
.address {
    margin-bottom: 40px;
}
.bottomDecoration {
    padding: 25px 0;
}
input,
textarea {
    outline: none;
}
textarea {
    width: 50%;
}
input {
    height: 30%;
}
.contactForm {
    width: 100%;
}
.inputs {
    width: 50%;
    margin-right: 4px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}
@media screen and (max-width: 768px) {
    .main {
        padding: 3vh 5vw;
        width: 100vw;
    }
    .mainHeader {
        padding-top: 5vh;
        order: 2;
    }
    .address {
        order: 3;
    }
    iframe {
        width: 100%;
        height: 35vh;
        order: 4;
    }
    .contactForm {
        order: 1;
    }
    .bottomDecoration {
        order: 5
    }
}