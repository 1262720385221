@import '../../styles/variables.scss';

.root {
  display: flex;
  flex-direction: column;
  @media (max-width: $lg) {
    align-items: center;
  }
}

.text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 16px;

  .link {
    color: #fff;
    text-decoration: underline;
  }

  @media (max-width: $lg) {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 20px;
  }
}

.app img {
  width: 190px;
  height: 56px;
}

.rowReverse {
  flex-direction: row-reverse;
  align-items: center;

  .app {
    margin-right: 32px;

    @media (max-width: $lg) {
      margin-right: 0;
    }
  }

  @media (max-width: $lg) {
    flex-direction: column;
  }
}
