.logoBlock {
  height: 132px;
  text-decoration: none;
  color: #554a4d;
  background-color: #f1f1e4;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  h1 {
    font: normal normal 30px/1.2em georgia, palatino, "book antiqua", "palatino linotype", serif;
    letter-spacing: 2px;
    margin-bottom: 4px;
  }
  h2 {
    font: normal normal normal 16px/1.3em helvetica-w01-light, helvetica-w02-light, sans-serif;
    letter-spacing: 5px;
  }
}
.logoDecoration {
  width: 137px;
  height: 31px;
  stroke: rgb(156, 142, 147);
  fill: rgb(156, 142, 147);
  margin-bottom: 4px;
}
.navLink {
  color: #c0babc;
  text-decoration: none;
  &Active {
    color: #f1f1e4;
  }
}

.topNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgba(39, 39, 37, 1);
  font: normal normal 16px/1.2em georgia, palatino, "book antiqua", "palatino linotype", serif;
  color: #FFF;
  height: 50px;
  padding: 1em;
}

.menu {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.menu > li {
  margin: 0 1rem;
}

.menuButtonContainer {
  display: none;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

#menuToggle {
  display: none;
}

.menuButton,
.menuButton::before,
.menuButton::after {
  display: block;
  background-color: #554a4d;
  position: absolute;
  height: 4px;
  width: 30px;
  border-radius: 2px;
}

.menuButton::before {
  content: '';
  margin-top: -8px;
}

.menuButton::after {
  content: '';
  margin-top: 8px;
}

#menuToggle:checked + .menuButtonContainer .menuButton::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

#menuToggle:checked + .menuButtonContainer .menuButton {
  background: rgba(255, 255, 255, 0);
}

#menuToggle:checked + .menuButtonContainer .menuButton::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

@media screen and (max-width: 768px) {
  .logoBlock {
    justify-content: start;
    height: auto;
    padding-bottom: 2vh;
    h1 {
      font-size: 24px;
    }
    h2 {
      font-size: 14px;
    }
  }
  .navLink {
    color: transparent;
  }
  #menuToggle:checked ~ .menu li .navLink {
    color: #554a4d;
  }
  .topNav {
    background-color: #f1f1e4;
    justify-content: flex-end;
    padding: 0 1.5em;
  }
  .menuButtonContainer {
    display: flex;
  }
  .menu {
    z-index: 2;
    position: absolute;
    top: 0;
    margin-top: 50px;
    left: 0;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  #menuToggle ~ .menu li {
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
  }
  #menuToggle:checked ~ .menu li {
    border-bottom: 1px solid #d8d8cc;
    height: 2.5em;
    padding: 0.5em;
  }
  .menu > li {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0.5em 0;
    width: 100%;
    background-color: #f1f1e4;
  }
}