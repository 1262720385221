* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  min-width: 360px;
  background-color: #252d3d;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  flex: 1 0 auto;
}

#root {
  position: relative;
  contain: paint;
  /* min-height: 100vh; */
  /* overflow:hidden; */
}

@media only screen and (min-height : 320px) and (max-height : 560px) {
	body {-webkit-text-size-adjust: none;}
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
